import reportData from '@/plugin/mixin/reportData';

export default {
  data() {
    return {
      qaList: [
        {
          q: '什么是答主成长体系呢？',
          a: '答主成长体系是评估答主在闪现一下内的回答创作行为及创作所处阶段的综合指数，回答内容越优质，成长速度越快，能够获得的创作权益越多，成长体系仍在不断的调整优化呢！',
        },
        {
          q: '如何加入答主成长体系呢？',
          a: '持续发布优质回答会获得成长值，累积到目标值后，答主成长中心会提示你可以申请答主身份啦。系统会根据你的社区贡献值和活跃度审核并发放答主专属身份勋章，你就可以进入答主成长体系查看你的等级和相应权益啦！\n成为答主之前，成长值在达到解锁目标值后将不再上涨，直到你申请答主身份；进入答主成长体系后，成长值将会从目标值开始计算，每周一更新。',
        },
        {
          q: '如何快速获得成长值呢？',
          a: '答主的等级与成长值有关：持续发布回答内容可以获得成长值；回答创作内容获得互动数（赞同、收藏、评论）越多，成长值越多；回答字数超过150字，可能会获得更多成长值噢！',
        },
        {
          q: '成长值多久计算更新一次？',
          a: '【星期一结算】所有用户每周一将会结算一次，未成为答主的用户，成长值累计到500但一直没有申请答主身份，将不会再加分，直到申请答主后继续每周一结算成长值',
        },
        {
          q: '什么是保级状态？',
          a: '如果你一周内没有回答过任何问题，成长值就会下降，当下降到初始成长值时，你的答主勋章也将不再支持佩戴外显，但成长值将不会继续下降，这就是进入了[保级状态]。但是只要你重新活跃起来，持续回答问题，成长值会增加，勋章也会再次外显噢~',
        },
        {
          q: '提问会获得成长值吗？',
          a: '目前提问不会获得成长值，也不作为答主考核的指标噢~但是创作优质回答的前提是要有真诚的提问，因此还请各位闪友积极贡献优质问题呢！',
        },
        {
          q: '哪些问答内容在社区不受欢迎呢？',
          a: '首先，“太水”的问题和回答都属于社区违规内容，还会被扣除相应成长值呢，请真诚提问和作答哦~\n其次，对于抄袭、未经许可搬运等不尊重内容创作者的行为，闪闪会严厉打击，还请保证你的问答内容的原创性哦~\n如果还有其他不受欢迎的问答内容，还请各位闪友及时举报呢！',
        },
        {
          q: '其他认证会被计入答主成长体系吗？',
          a: '目前社区新上线了答主成长体系，所有闪友都有机会成为答主、获得答主勋章噢！其他认证身份的成长体系也在规划中啦，敬请期待！',
        },
      ],
    };
  },
  mixins: [reportData],
  computed: {
    canShow() {
      return index => this.qaList[index].isShow;
    },
  },
  methods: {
    showItem(index) {
      this.$set(this.qaList[index], 'isShow', !this.qaList[index].isShow);
      this.reportData({
        pageid: '1411',
        moduleid: '00056',
        eventtype: '03',
        extendid: '14',
      }, '常见问题页页面点击');
    },
  },
  mounted() {
    this.reportData({
      pageid: '1411',
      moduleid: '00056',
      eventtype: '02',
      extendid: '11',
    }, '常见问题页页面曝光');
  },
};
