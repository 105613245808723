/**
 * 数据上报
 * 通过provie 注入到页面的子组件中
 * 页面的data中必须包含下面参数
 * reportDataParams
 */
import { mapState } from 'vuex';
import reportApi from '@/api/report';
import jsbridge from '@/jsbridge';
import { logger } from '@/util/logger';

export default {
  data() {
    return {
      isScrollEventReported: false,
      // {watch: Observable[], el: DOMELEMENT, scrollTop: Number, height: Number, reportParams: params}
      exposeElementStore: [],
      pageShowTime: 0,
      staytimeStart: (new Date()).getTime(),
    };
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp,
      isAndroid: state => state.isAndroid,
      isIOS: state => state.isIOS,
      userInfo: state => state.user.userInfo,
    }),
  },
  provide() {
    return {
      reportData: this.reportData,
    };
  },
  mounted() {
    //
  },
  beforeDestroy() {
    if (this.isApp) {
      this.reportStayTimeCall();
    } else {
      this.reportStayTimeH5Call();
    }
  },
  methods: {
    reportData(params, desc) {
      const obj = {
        indexid: 1, // 默认是1，列表里面应该传第几项
        ...this.reportDataParams,
        ...params,
      };
      if (this.$route.query.pageFrom) {
        obj.prev_id = this.$route.query.pageFrom;
      }
      return reportApi(obj, desc);
    },
    registerReportEvent() {
      if (this.isApp) {
        this.staytimeStart = (new Date()).getTime();
        jsbridge.ui.setOnVisibilityChange({
          callback: (res) => {
            logger.info('page setOnVisibilityChange', res);
            if (res.result === 0) {
              if (!res.data.visibilityState) {
                this.reportStayTimeCall();
              } else {
                this.staytimeStart = (new Date()).getTime();
              }
            }
          },
        });
      } else {
        this.staytimeStart = (new Date()).getTime();
        document.addEventListener('visibilitychange', () => {
          // console.log('visibilitychange', event);
          if (document.hidden) {
            const now = (new Date()).getTime();
            const staytime = Math.round((now - this.staytimeStart) / 1000);
            if (staytime > 0 && staytime < 3600) {
              this.staytimeStart = now;
              this.reportStayTimeH5Call();
            }
          } else {
            this.staytimeStart = (new Date()).getTime();
            // console.log('页面展示', this.staytimeStart);
          }
        }, false);
      }
    },
    // 引用页面的方法
    reportStayTimeCall() {
      if (!this.isApp || !this.staytimeStart) return;
      const now = (new Date()).getTime();
      const staytime = Math.round((now - this.staytimeStart) / 1000);
      if (staytime > 0 && staytime < 3600) {
        this.staytimeStart = now;
        if (this.reportStayTime) {
          this.reportStayTime(staytime);
        }
      }
    },
    // 引用页面的方法
    reportStayTimeH5Call() {
      if (this.isApp || !this.staytimeStart) return;
      const now = (new Date()).getTime();
      const staytime = Math.round((now - this.staytimeStart) / 1000);
      if (staytime > 0 && staytime < 3600) {
        this.staytimeStart = now;
        if (this.reportStayTimeH5) {
          this.reportStayTimeH5(staytime);
        }
      }
    },
  },
};
