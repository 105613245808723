<template>
  <div class="p-answer-growth-qa">
    <div class="qa-content">
      <div
        v-for="(item, index) in qaList"
        :key="index"
        class="content-box"
      >
        <div
          @click="showItem(index)"
          class="title-box"
        >
          <span class="title">{{ index + 1 }}.{{ item.q }}</span>
          <i :class="{'more-icon': true, show: canShow(index)}" />
        </div>
        <div
          v-show="canShow(index)"
          class="text-box"
        >
          <p>{{ item.a }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./css/index.scss"></style>

<script>
import vm from './vm';

export default {
  ...vm,
  name: 'PageAnswerGrowthQA',
};
</script>
