import { mapState } from 'vuex';
import { throttle } from 'lodash';

import jsbridge, { popBack } from '@/jsbridge';

import PostHeader from '@/component/post-header/index.vue';
import Dialog from '@/ui/dialog/index.vue';
import Toast from '@/ui/toast/index.vue';

const PUBLISH_HANDLER = Symbol('feedback#publishHandler');

export default {
  name: 'PageSettingFeedback',
  components: {
    PostHeader,
    Dialog,
    Toast,
  },
  data() {
    return {
      formData: {
        type: 0,
        text: '',
        contact: '',
      },
      formDataBackUp: {},
      dialogShow: false,
      isPublish: false,
      showToast: false,
      [PUBLISH_HANDLER]: null,
      toastText: '',
    };
  },
  computed: {
    ...mapState({
      isAndroid: state => state.isAndroid,
      typeList: state => state.setting.feedbackTypeList,
    }),
    textLen() {
      return this.formData.text.length;
    },
  },
  beforeDestroy() {
    jsbridge.core.call('ui/setBackListener', {
      action: 0,
    });
  },
  created() {
    this.formDataBackUp = { ...this.formData };
    this.$store.dispatch('setting/getFeedbackTypeList');
    jsbridge.core.call('ui/setBackListener', {
      action: 1,
      callback: () => {
        this.checkPageBackConfirm();
      },
    });
  },
  mounted() {
    if (this.isAndroid) {
      window.addEventListener('resize', () => {
        if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
          window.setTimeout(() => {
            document.activeElement.scrollIntoViewIfNeeded();
          }, 0);
        }
      });
    }
  },
  methods: {
    checkPageBackConfirm() {
      const isChange = !Object.keys(this.formData).every(key => this.formData[key] === this.formDataBackUp[key]);
      if (!isChange) {
        this.onDialogConfirm();
      } else {
        this.dialogShow = !this.isPublish;
      }
    },
    onDialogConfirm() {
      popBack();
    },
    onDialogCancel() {
      this.dialogShow = false;
    },
    publishThrottle() {
      // 防抖，5秒内才触发一次
      return throttle(() => {
        jsbridge.core.call('user/feedback', {
          type_id: this.formData.type,
          text: this.formData.text,
          uin: this.formData.contact,
        });
        this.isPublish = true;
        this.toast('提交成功').then(() => {
          this.onDialogConfirm();
        });
      }, 5000);
    },
    publishHandler() {
      if (!this[PUBLISH_HANDLER]) {
        this[PUBLISH_HANDLER] = this.publishThrottle();
      }
      if (!this.formData.type) {
        return this.toast('您还未选择反馈问题类型哟~');
      }
      if (!this.formData.contact) {
        return this.toast('您还未填写联系方式哟~');
      }
      if (!/^([^@]+)@.+\.[^.]+$/.test(this.formData.contact) && !/^\d+$/.test(this.formData.contact)) {
        return this.toast('您填写的联系方式格式错误，请输入QQ或电话号');
      }
      return this[PUBLISH_HANDLER]();
    },
    toast(text) {
      this.toastText = text;
      this.showToast = true;
      return new Promise((resolve) => {
        setTimeout(() => {
          this.showToast = false;
          resolve();
        }, 1500);
      });
    },
  },
};
